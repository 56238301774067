<template>
  <div class="page-thank-you">
    <CompanyMatches :companies="companies" />

    <div v-if="companies.length > 0" class="tips container">
      <h2 class="tips__title">
        {{ $t('tips-title') }}
      </h2>

      <div class="tips__container">
        <div class="mt-12 md:mt-16 w-full flex flex-col md:flex-row gap-y-9">
          <div
            v-for="tip of tips"
            :key="tip.text"
            class="flex md:flex-col md:items-center md:w-1/3"
          >
            <div>
              <div
                class="flex items-center justify-center h-10 w-10 rounded-full bg-primary text-white"
              >
                <component :is="tip.icon" class="h-6 w-6" />
              </div>
            </div>
            <div class="md:pl-4">
              <p class="ml-3 leading-5 md:ml-0 md:mt-3 md:text-center">
                {{ $t(tip.text) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-thank-you__cross-sell">
      <div class="cross-sell">
        <div class="cross-sell__wrapper container">
          <div class="cross-sell__text">
            <h2 class="cross-sell__title">
              {{ $t('cross-sell-title') }}
            </h2>

            <p class="cross-sell__body">
              {{ $t('cross-sell-body') }}
            </p>
          </div>

          <div class="cross-sell__image">
            <img :src="huisprojecten" alt="Huisprojecten.nl" />
          </div>
        </div>
      </div>

      <div class="cross-sell__bottom">
        <div class="flex justify-start md:w-1/2">
          <TBtnLink
            id="linkToHuisprojecten"
            class="bg-primary hover:bg-primary-dark"
            href="https://www.huisprojecten.nl"
            target="_blank"
          >
            {{ $t('cross-sell-button') }}
          </TBtnLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/jupiter/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import IconPhone from 'chimera/all/themes/jupiter/images/icons/IconPhone'
import IconMail from 'chimera/all/themes/jupiter/images/icons/IconMail'
import IconCheckCircle from 'chimera/all/themes/jupiter/images/icons/IconCheckCircle'
import CompanyMatches from 'chimera/all/page/thankYou/CompanyMatches.vue'

export default {
  components: {
    CompanyMatches,
    Usps,
  },

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag afgerond',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top makelaars uit jouw regio. ✓Dé beste prijs voor jouw makelaar ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/offertes-aanvragen/aanvraag-afgerond',
      tips: [
        {
          icon: IconPhone,
          text: 'tips1-text',
        },
        {
          icon: IconMail,
          text: 'tips2-text',
        },
        {
          icon: IconCheckCircle,
          text: 'tips3-text',
        },
      ],
      image: require('chimera/all/assets/images/handshake.png'),
      huisprojecten: require('chimera/all/assets/images/huisprojecten_screen.png'),
      companies: [],
    }
  },

  /**
   *
   */
  beforeMount() {
    this.companies = this.$store.getters['lead/getResult']('companies', [])
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Dankjewel voor je aanvraag!",
    "subtitle": "De makelaars nemen zo snel mogelijk contact op met jou.",
    "tips-title": "Tips om even door te nemen",
    "tips1-text": "Zorg dat je je telefoon en agenda bij de hand hebt, want specialisten kunnen je telefonisch benaderen.",
    "tips2-text": "Vergeet niet om je spamfolder van je e-mail te controleren.",
    "tips3-text": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "cross-sell-title": "Bespaar ook op de kosten van andere diensten",
    "cross-sell-body": "Ga nu naar Huisprojecten.nl en vind gratis en vrijblijvend de beste deals van meer dan 2.000 geselecteerde specialisten.",
    "cross-sell-button": "Ga naar Huisprojecten.nl"
  },
  "nl-BE": {
    "title": "Bedankt voor uw aanvraag!",
    "subtitle": "De makelaars nemen zo snel mogelijk contact met u op.",
    "tips-title": "Enkele tips om in gedachten te houden",
    "tips1-text": "Houd uw telefoon en agenda bij de hand, aangezien specialisten telefonisch contact met u kunnen opnemen.",
    "tips2-text": "Vergeet niet uw spamfolder van uw e-mail te controleren.",
    "tips3-text": "Vergelijk niet alleen op prijs, maar let ook op kwaliteit, service en betrouwbaarheid.",
    "cross-sell-title": "Bespaar ook op andere diensten",
    "cross-sell-body": "Bezoek nu Huisprojecten.be en vind gratis en vrijblijvend de beste deals van meer dan 2.000 geselecteerde specialisten.",
    "cross-sell-button": "Bezoek Huisprojecten.be"
  },
  "es-ES": {
    "title": "¡Gracias por tu solicitud!",
    "subtitle": "Los agentes se pondrán en contacto contigo lo antes posible.",
    "tips-title": "Consejos que deberías considerar",
    "tips1-text": "Ten a mano tu teléfono y agenda, ya que los especialistas podrían contactarte por teléfono.",
    "tips2-text": "No olvides revisar tu carpeta de spam en tu correo electrónico.",
    "tips3-text": "No solo mires el precio, compara también la calidad, el servicio y la fiabilidad.",
    "cross-sell-title": "Ahorra también en otros servicios",
    "cross-sell-body": "Visita ahora Huisprojecten.es y encuentra gratuitamente las mejores ofertas de más de 2.000 especialistas seleccionados.",
    "cross-sell-button": "Ir a Huisprojecten.es"
  },
  "de-DE": {
    "title": "Vielen Dank für Ihre Anfrage!",
    "subtitle": "Die Makler werden sich so schnell wie möglich bei Ihnen melden.",
    "tips-title": "Tipps zur Vorbereitung",
    "tips1-text": "Halten Sie Ihr Telefon und Ihren Terminkalender bereit, da die Spezialisten Sie telefonisch kontaktieren könnten.",
    "tips2-text": "Vergessen Sie nicht, Ihren Spam-Ordner in Ihrem E-Mail-Postfach zu überprüfen.",
    "tips3-text": "Achten Sie nicht nur auf den Preis, sondern vergleichen Sie auch Qualität, Service und Zuverlässigkeit.",
    "cross-sell-title": "Sparen Sie auch bei anderen Dienstleistungen",
    "cross-sell-body": "Besuchen Sie jetzt Huisprojecten.de und finden Sie kostenlos die besten Angebote von mehr als 2.000 ausgewählten Spezialisten.",
    "cross-sell-button": "Besuchen Sie Huisprojecten.de"
  },
  "fr-BE": {
    "title": "Merci pour votre demande !",
    "subtitle": "Les agents prendront contact avec vous dans les plus brefs délais.",
    "tips-title": "Quelques conseils à garder en tête",
    "tips1-text": "Gardez votre téléphone et votre agenda à portée de main, car les spécialistes pourraient vous contacter par téléphone.",
    "tips2-text": "N'oubliez pas de vérifier votre dossier de spam dans votre boîte e-mail.",
    "tips3-text": "Ne vous concentrez pas uniquement sur le prix, comparez également la qualité, le service et la fiabilité.",
    "cross-sell-title": "Économisez également sur d'autres services",
    "cross-sell-body": "Rendez-vous maintenant sur Huisprojecten.be et trouvez gratuitement les meilleures offres de plus de 2 000 spécialistes sélectionnés.",
    "cross-sell-button": "Rendez-vous sur Huisprojecten.be"
  },
  "fr-FR": {
    "title": "Merci pour votre demande !",
    "subtitle": "Les agents prendront contact avec vous dans les plus brefs délais.",
    "tips-title": "Quelques conseils à garder en tête",
    "tips1-text": "Gardez votre téléphone et votre agenda à portée de main, car les spécialistes pourraient vous contacter par téléphone.",
    "tips2-text": "N'oubliez pas de vérifier votre dossier de spam dans votre boîte e-mail.",
    "tips3-text": "Ne vous concentrez pas uniquement sur le prix, comparez également la qualité, le service et la fiabilité.",
    "cross-sell-title": "Économisez également sur d'autres services",
    "cross-sell-body": "Rendez-vous maintenant sur Huisprojecten.fr et trouvez gratuitement les meilleures offres de plus de 2 000 spécialistes sélectionnés.",
    "cross-sell-button": "Rendez-vous sur Huisprojecten.fr"
  },
  "it-IT": {
    "title": "Grazie per la tua richiesta!",
    "subtitle": "Gli agenti ti contatteranno al più presto.",
    "tips-title": "Consigli da tenere a mente",
    "tips1-text": "Tieni a portata di mano il tuo telefono e l'agenda, poiché gli specialisti potrebbero contattarti telefonicamente.",
    "tips2-text": "Non dimenticare di controllare la tua cartella spam nella posta elettronica.",
    "tips3-text": "Non guardare solo il prezzo, ma confronta anche qualità, servizio e affidabilità.",
    "cross-sell-title": "Risparmia anche su altri servizi",
    "cross-sell-body": "Visita subito Huisprojecten.it e trova gratuitamente le migliori offerte di oltre 2.000 specialisti selezionati.",
    "cross-sell-button": "Visita Huisprojecten.it"
  }
}
</i18n>
